class CookieConsent {

    constructor(containerId) {
        const cookieName = '_dr_cookieConsent'

        const setCookieConsent = (container, confirmed) => {
            container.classList.remove('visible')
            setCookie(cookieName, +confirmed)
            document.dispatchEvent(new CustomEvent(cookieName, {detail: {confirmed}}))
        }
        const showCookieConsent = container => {
            container.querySelectorAll('.buttons-area button').forEach(button => button.addEventListener('click', ({target: {dataset: {value = '0'} = {}} = {}}) => setCookieConsent(container, Boolean(Number.parseInt(value)))))
            container.classList.add('visible')
        }

        document.addEventListener('DOMContentLoaded', () => {
            const container = document.getElementById(containerId)
            if (!readCookie(cookieName)) {
                showCookieConsent(container)
            }
        })
    }
}

new CookieConsent('cookie-consent')